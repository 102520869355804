@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 0;
    height: 0;
}

.range:active .range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid blue;
    border-radius: 50%;
    background: #fff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.6);
}

input[type=range][orient=vertical]
{
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    width: 8px;
    height: 175px;
    padding: 0 5px;
}